import { useEffect, memo } from 'react';
import { Button } from 'react-bootstrap';
import { useDropzone, DropzoneOptions } from 'react-dropzone';
import { OnChangeFileUpload } from 'types';
import { formatBytes } from 'utils';
import {
  Wrapper,
  Label,
  ImageUpload,
  ImageWrapper,
  ImageLabel,
  FileName,
  FileSize,
  ImageUploadSuccess,
  ImageErrorWrapper,
  ImageUploadFail,
  ErrorTextWrapper,
  ErrorFileName,
  ImageErrorOuter,
  ErrorText,
  Subtitle,
} from './styles';
import { RotatingLines } from 'react-loader-spinner';

interface Props {
  label: string;
  description?: string;
  name?: string;
  maxSize?: number;
  options?: DropzoneOptions;
  onChange?: OnChangeFileUpload;
  error?: boolean;
  errorMessage?: string;
  isLoading?: boolean;
  errorText?: string;
}

const defaultOptions: DropzoneOptions = {
  noClick: true,
  noKeyboard: true,
  maxFiles: 1,
  multiple: false,
  accept: '.xls, .xlsx, .csv',
};

const defaultMaxSize = 1024 * 1024 * 100; // 100 MB;

function FileUploadMerge({
  label,
  description,
  name,
  options,
  onChange,
  error,
  errorMessage,
  isLoading,
  errorText,
}: Props): JSX.Element {
  const { getRootProps, getInputProps, open, acceptedFiles, fileRejections } =
    useDropzone({
      ...defaultOptions,
      validator: function (file) {
        if (
          (options?.maxSize && file.size > options.maxSize) ||
          file.size > defaultMaxSize
        ) {
          return {
            code: 'maxSize',
            message: `Maksimal ukuran file ${formatBytes(
              options?.maxSize || defaultMaxSize,
            )}`,
          };
        }

        return null;
      },
      ...options,
    });

  const errorMess = errorText;
  function renderBody(): JSX.Element | null {
    if (fileRejections.length > 0) {
      const file = fileRejections[0].file;
      const errors = fileRejections[0].errors;
      const errorText = errors[0].message;
      return (
        <ImageWrapper>
          <ImageErrorOuter>
            <ImageErrorWrapper>
              <ImageUploadFail />
              <ErrorTextWrapper>
                <ErrorFileName>{file.name}</ErrorFileName>
                <FileSize>Size: {formatBytes(file.size)}</FileSize>
              </ErrorTextWrapper>
            </ImageErrorWrapper>
          </ImageErrorOuter>
          <ErrorText>{errorText || errorMess}</ErrorText>
          <Button variant="dark" onClick={open}>
            Re-Upload File
          </Button>
        </ImageWrapper>
      );
    }

    if (acceptedFiles.length === 0) {
      if (error && errorMessage) {
        return (
          <>
            <ImageWrapper>
              <ImageUpload />
              <ErrorText>{errorMessage}</ErrorText>
            </ImageWrapper>
            <Button variant="dark" onClick={open}>
              Upload File
            </Button>
          </>
        );
      }

      return (
        <>
          <ImageWrapper>
            <ImageUpload />
            <ImageLabel>Select file of drag here</ImageLabel>
          </ImageWrapper>
          <Button variant="dark" onClick={open}>
            Upload File
          </Button>
        </>
      );
    }

    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];

      if (isLoading) {
        return (
          <>
            <ImageWrapper>
              <RotatingLines
                strokeColor=" #00A4CA"
                strokeWidth="3"
                animationDuration="0.75"
                width="70"
                visible={true}
              />
            </ImageWrapper>
            <Button variant="dark" disabled>
              Uploading
            </Button>
          </>
        );
      }

      if (errorMess) {
        return (
          <ImageWrapper>
            <ImageErrorOuter>
              <ImageErrorWrapper>
                <ImageUploadFail />
                <ErrorTextWrapper>
                  <ErrorFileName>{file.name}</ErrorFileName>
                  <FileSize>Size: {formatBytes(file.size)}</FileSize>
                </ErrorTextWrapper>
              </ImageErrorWrapper>
            </ImageErrorOuter>
            <ErrorText>{errorMess}</ErrorText>
            <Button variant="dark" onClick={open}>
              Re-Upload File
            </Button>
          </ImageWrapper>
        );
      }
      if (!isLoading) {
        return (
          <ImageWrapper>
            <ImageUploadSuccess />
            <FileName>{file.name}</FileName>
            <Button variant="outline-secondary" onClick={open}>
              Change File
            </Button>
          </ImageWrapper>
        );
      }
    }

    return null;
  }

  useEffect(
    function () {
      if (onChange && name && acceptedFiles.length > 0 && acceptedFiles[0]) {
        const file = acceptedFiles[0];
        onChange(name, file);
      }
    },
    [name, onChange, acceptedFiles.length, acceptedFiles[0]],
  );

  return (
    <Wrapper {...getRootProps()}>
      <input {...getInputProps()} />
      <Label>{label}</Label>
      <Subtitle>{description}</Subtitle>
      {renderBody()}
    </Wrapper>
  );
}

export default memo(FileUploadMerge);
