import { useState, useEffect } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { useForm } from 'hooks';
import { MainContent, PageInfo } from 'components';
import {
  getMerchantTypes,
  getIssuers,
  // createRecon,
  getCloudStorage,
  createAsyncReconOvo,
  createAsyncReconMandiri,
  createAsyncReconLinkAja,
  createAsyncReconLinkAjaMsme,
  createAsyncReconShopeeMcd,
  createAsyncReconKredivoMcd,
  createAsyncReconUangmeMcd,
  createAsyncReconLinkAjaExpansion,
} from 'services';
// import { CreateReconResponse } from 'services/recon/interfaces';
import { profileSelector } from 'modules';
import { useSelector, shallowEqual } from 'react-redux';
import {
  isAuthenticated,
  MERCHANT_TYPES,
  ISSUERS,
  validateShopeeSettlementHeader,
  validateKredivoSettlementHeader,
  validateMandiriSettlementHeader,
  validateLinkAjaMCDReportHeader,
  validateBalanceReportHeader,
  validatePhpDeliveryReportHeader,
  validateYtiSettelementHeader,
  validateMcdSettelementHeader,
  validateOpsSettlementHeader,
  // validateLinkAjaMCDReportMergeHeader,
  validateLinkAjaMCDMSMEReportMergeHeader,
  MESSAGES,
  PERMISSION_LABELS,
  validateLinkAjaDataExpansionHeader,
  validateOvoSettlementHeader,
} from 'utils';
import { ActionMeta, SingleValue } from 'react-select';
import { errorBag, loadingScreen, popupMessage } from 'controls';
import { OptionsType } from 'types';
import FormComponent from './Form';
import FormFileComponent from './FormFile';
import { FormState, ErrorState } from './interfaces';
import axios from 'axios';
import { toast, Toaster } from 'react-hot-toast';

const initialFormState: FormState = {
  merchantType: undefined,
  date: '',
  issuer: undefined,
  issuerFile: undefined,
  youtapBalanceFile: undefined,
  phpTransactionFile: undefined,
  ytiSettlementFile: undefined,
  linkAjaMCDReportFile: undefined,
  ytiMcdSettlementFile: undefined,
  linkAjaMcdPortalSettlementFile: undefined,
  opsSettlementFile: undefined,
  dataExpansionFile: undefined,
  ovoSettlementFile: undefined,
};

const initialErrorState: ErrorState = {
  merchantType: '',
  date: '',
  issuer: '',
  issuerFile: '',
  ytiSettlementFile: '',
  youtapBalanceFile: '',
  phpTransactionFile: '',
  linkAjaMCDReportFile: '',
  ytiMcdSettlementFile: '',
  linkAjaMcdPortalSettlementFile: '',
  opsSettlementFile: '',
  dataExpansionFile: '',
  ovoSettlementFile: '',
};

function ConsolidateData(): JSX.Element {
  const profile = useSelector(profileSelector, shallowEqual);
  const [form, setForm] = useForm(initialFormState);
  const [error, setError] = useState(initialErrorState);

  const [linkAjaMCDReportUrl, setLinkAjaMCDReportUrl] = useState('');
  const [ytiSettlementUrl, setYtiSettlementUrl] = useState('');
  const [youtapBalanceUrl, setYoutapBalanceUrl] = useState('');
  const [phpTransactionUrl, setPhpTransactionUrl] = useState('');
  const [opsSettlementUrl, setOpsSettlementUrl] = useState('');
  const [ytiMcdSettlementUrl, setYtiMcdSettlementUrl] = useState('');
  const [issuerUrl, setIssuerUrl] = useState('');
  const [dataExpansionUrl, setDataExpansionUrl] = useState('');
  const [ovoUrl, setOvoUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const isLogin = isAuthenticated();

  function onChangeFormInput(e: React.ChangeEvent<HTMLInputElement>): void {
    const { name, value } = e.target;
    setForm(name, value);

    // eslint-disable-next-line security/detect-object-injection
    if (error[name as keyof typeof error] !== '') {
      setError((prev) => ({ ...prev, [name]: '' }));
    }
  }

  function onChangeSelectInput(
    selectValue: SingleValue<OptionsType>,
    meta: ActionMeta<OptionsType>,
  ): void {
    setForm(meta.name, selectValue);
    const field = meta.name as keyof typeof error;

    if (error[field as keyof typeof error] !== '') {
      setError((prev) => ({ ...prev, [field]: '' }));
    }
  }

  // this function for convert type FILE to BASE64
  // const fileToDataUri = (file: File): Promise<any> =>
  //   new Promise((resolve) => {
  //     const reader = new FileReader();
  //     reader.onload = (event: any): void => {
  //       resolve(event.target.result);
  //     };
  //     reader.readAsDataURL(file);
  //   });

  // this function for upload file to google cloude storage
  async function handleFileUpload(value: File, url: string): Promise<void> {
    setIsLoading(true);
    axios({
      method: 'put',
      url: url,
      headers: {
        'Content-Type': 'application/octet-stream',
      },
      data: value,
    })
      .then(function (response) {
        if (response.status === 200) {
          setIsLoading(false);
          toast.success('File Berhasil Diupload', {
            style: {
              color: '#ffff',
              background: '#7ED321',
            },
            iconTheme: {
              primary: '#7ED321',
              secondary: '#ffff',
            },
          });
        }
      })
      .catch(() => {
        setIsLoading(false);
        toast.error('File Gagal Diupload', {
          style: {
            color: '#ffff',
            background: '#FF0044',
          },
          iconTheme: {
            primary: '#FF0044',
            secondary: '#ffff',
          },
        });
      });
  }

  // get File when onChange
  async function onChangeFile(name: string, value: File): Promise<void> {
    if (value !== form[name as keyof typeof form]) {
      setForm(name, value);
      switch (name) {
        case 'linkAjaMCDReportFile':
          getCloudStorage(value.name).then(function (data) {
            setLinkAjaMCDReportUrl(data.downloadUrls[0]);
            if (data.uploadUrls[0]) {
              handleFileUpload(value, data.uploadUrls[0]);
            }
          });
          break;
        case 'ytiSettlementFile':
          getCloudStorage(value.name).then(function (data) {
            setYtiSettlementUrl(data.downloadUrls[0]);
            if (data.uploadUrls[0]) {
              handleFileUpload(value, data.uploadUrls[0]);
            }
          });
          break;
        case 'youtapBalanceFile':
          getCloudStorage(value.name).then(function (data) {
            setYoutapBalanceUrl(data.downloadUrls[0]);
            if (data.uploadUrls[0]) {
              handleFileUpload(value, data.uploadUrls[0]);
            }
          });
          break;
        case 'phpTransactionFile':
          getCloudStorage(value.name).then(function (data) {
            setPhpTransactionUrl(data.downloadUrls[0]);
            if (data.uploadUrls[0]) {
              handleFileUpload(value, data.uploadUrls[0]);
            }
          });
          break;
        case 'opsSettlementFile':
          getCloudStorage(value.name).then(function (data) {
            setOpsSettlementUrl(data.downloadUrls[0]);
            if (data.uploadUrls[0]) {
              handleFileUpload(value, data.uploadUrls[0]);
            }
          });
          break;
        case 'ytiMcdSettlementFile':
          getCloudStorage(value.name).then(function (data) {
            setYtiMcdSettlementUrl(data.downloadUrls[0]);
            if (data.uploadUrls[0]) {
              handleFileUpload(value, data.uploadUrls[0]);
            }
          });
          break;
        case 'issuerFile':
          getCloudStorage(value.name).then(function (data) {
            setIssuerUrl(data.downloadUrls[0]);
            if (data.uploadUrls[0]) {
              handleFileUpload(value, data.uploadUrls[0]);
            }
          });
          break;
        case 'dataExpansionFile':
          getCloudStorage(value.name).then(function (data) {
            setDataExpansionUrl(data.downloadUrls[0]);
            if (data.uploadUrls[0]) {
              handleFileUpload(value, data.uploadUrls[0]);
            }
          });
          break;
        case 'ovoSettlementFile':
          getCloudStorage(value.name).then(function (data) {
            setOvoUrl(data.downloadUrls[0]);
            if (data.uploadUrls[0]) {
              handleFileUpload(value, data.uploadUrls[0]);
            }
          });
          break;
        default:
          popupMessage({
            show: true,
            type: 'danger',
            title: MESSAGES.FILE_WRONG,
            message: `Tidak ada file yang di upload...`,
          });
          break;
      }
      if (error[name as keyof typeof error]) {
        setError((prev) => ({ ...prev, [name]: '' }));
      }
    }
  }

  async function validateForm(): Promise<[boolean, ErrorState]> {
    const newError = { ...error };

    if (!form.merchantType) {
      newError.merchantType = 'Silahkan pilih Merchant Type';
    }

    if (!form.date) {
      newError.date = 'Silahkan masukan Recon Date';
    }

    if (!form.issuer) {
      newError.issuer = 'Silahkan pilih Issuer Company';
    }

    // if (form.issuer?.label !== ISSUERS.LINK_AJA) {
    //   if (!form.issuerFile) {
    //     newError.issuerFile = 'Mohon upload filenya';
    //   }
    // }
    // if (!form.ytiSettlementFile) {
    //   newError.ytiSettlementFile = 'Mohon upload filenya';
    // }
    if (form.issuer?.label === ISSUERS.MANDIRI_LIVIN) {
      if (form.issuerFile) {
        if (!(await validateMandiriSettlementHeader(form.issuerFile))) {
          newError.issuerFile = MESSAGES.FILE_NOT_MANDIRI_SETTLEMENT;
        }
      }
    }
    if (form.issuer?.label === ISSUERS.KREDIVO) {
      if (form.issuerFile) {
        if (!(await validateKredivoSettlementHeader(form.issuerFile))) {
          newError.issuerFile = MESSAGES.FILE_NOT_KREDIVO_SETTLEMENT;
        }
      }
    }
    if (form.issuer?.label === ISSUERS.SHOPEE) {
      if (form.issuerFile) {
        if (!(await validateShopeeSettlementHeader(form.issuerFile))) {
          newError.issuerFile = MESSAGES.FILE_NOT_SHOPEE_SETTLEMENT;
        }
      }
    }

    if (form.issuer?.label === ISSUERS.UANGME) {
      if (
        form.linkAjaMCDReportFile &&
        form.merchantType?.label === MERCHANT_TYPES.MCD
      ) {
        if (
          !(await validateLinkAjaMCDReportHeader(form.linkAjaMCDReportFile))
        ) {
          newError.linkAjaMCDReportFile = MESSAGES.FILE_NOT_LINKAJA_MCD_REPORT;
        }
      }
    }
    if (form.issuer?.label === ISSUERS.LINK_AJA) {
      if (
        form.linkAjaMCDReportFile &&
        form.merchantType?.label === MERCHANT_TYPES.MCD
      ) {
        if (
          !(await validateLinkAjaMCDReportHeader(form.linkAjaMCDReportFile))
        ) {
          newError.linkAjaMCDReportFile = MESSAGES.FILE_NOT_LINKAJA_MCD_REPORT;
        }
      }
      if (
        form.merchantType?.label === MERCHANT_TYPES.MSME &&
        form.linkAjaMCDReportFile
      ) {
        if (
          !(await validateLinkAjaMCDMSMEReportMergeHeader(
            form.linkAjaMCDReportFile,
          ))
        ) {
          newError.linkAjaMCDReportFile = MESSAGES.FILE_NOT_LINKAJA_MSME_REPORT;
        }
      }
      if (
        form.merchantType?.label === MERCHANT_TYPES.EXPANSION &&
        form.linkAjaMCDReportFile
      ) {
        if (
          !(await validateLinkAjaDataExpansionHeader(form.linkAjaMCDReportFile))
        ) {
          newError.linkAjaMCDReportFile =
            MESSAGES.FILE_NOT_LINKAJA_DATAEXPANSION;
        }
      }

      if (form.youtapBalanceFile) {
        if (!(await validateBalanceReportHeader(form.youtapBalanceFile))) {
          newError.youtapBalanceFile = MESSAGES.FILE_NOT_BALANCE_REPORT;
        }
      }
      if (form.phpTransactionFile) {
        if (!(await validatePhpDeliveryReportHeader(form.phpTransactionFile))) {
          newError.phpTransactionFile = MESSAGES.FILE_NOT_PHP_TRANSACTION;
        }
      }
      if (form.ytiSettlementFile) {
        if (!(await validateYtiSettelementHeader(form.ytiSettlementFile))) {
          newError.ytiSettlementFile = MESSAGES.FILE_NOT_YTI_SETTLEMENT;
        }
      }
      if (form.ytiMcdSettlementFile) {
        if (!(await validateMcdSettelementHeader(form.ytiMcdSettlementFile))) {
          newError.ytiMcdSettlementFile = MESSAGES.FILE_NOT_MCD_SETTLEMENT;
        }
      }
      if (form.opsSettlementFile) {
        if (!(await validateOpsSettlementHeader(form.opsSettlementFile))) {
          newError.opsSettlementFile = MESSAGES.FILE_NOT_MCD_SETTLEMENT;
        }
      }
      if (form.ovoSettlementFile) {
        if (!(await validateOvoSettlementHeader(form.ovoSettlementFile))) {
          newError.ovoSettlementFile = MESSAGES.FILE_NOT_OVO_SETTLEMENT;
        }
      }
    }

    const invalid = Object.values(newError).some((e) => e !== '');
    return [!invalid, newError];
  }

  // Function when run consolidate
  async function onSubmit(e: React.SyntheticEvent): Promise<void> {
    e.preventDefault();
    loadingScreen(true);
    const [valid, newError] = await validateForm();
    if (!valid) {
      setError(newError);
      loadingScreen(false);
      return;
    }

    // Check ISSUER and MERCHANT TYPE for create recon consolidate

    // Create recon ISSUER OVO & MERCHANT TYPE MCD
    if (
      form.issuer?.label === ISSUERS.OVO &&
      form.merchantType?.label === MERCHANT_TYPES.MCD
    ) {
      try {
        loadingScreen(true);
        const result = await createAsyncReconOvo({
          merchantTypeId: form.merchantType.value,
          issuerId: form.issuer.value,
          issuerName: form.issuer.label,
          reconDate: form.date,
          ytiMcdSettlement: ytiMcdSettlementUrl,
          ovoSettlement: ovoUrl,
        });
        loadingScreen(false);
        if (result) {
          popupMessage({
            show: true,
            type: 'waiting',
            title: 'Data Sedang Diproses',
            message: 'Periksa status datamu di menu “Consolidate Report”',
          });
        }
      } catch (err) {
        loadingScreen(false);
        errorBag(err);
      }
    }

    // Create recon ISSUER MANDIRI & MERCHANT TYPE MCD
    if (
      form.issuer?.label === ISSUERS.MANDIRI_LIVIN &&
      form.merchantType?.label === MERCHANT_TYPES.MCD
    ) {
      try {
        loadingScreen(true);
        const result = await createAsyncReconMandiri({
          merchantTypeId: form.merchantType.value,
          issuerId: form.issuer.value,
          issuerName: form.issuer.label,
          reconDate: form.date,
          ytiMcdSettlement: ytiSettlementUrl,
          mandiriSettlement: issuerUrl,
        });
        loadingScreen(false);
        if (result) {
          popupMessage({
            show: true,
            type: 'waiting',
            title: 'Data Sedang Diproses',
            message: 'Periksa status datamu di menu “Consolidate Report”',
          });
        }
      } catch (err) {
        loadingScreen(false);
        errorBag(err);
      }
    }

    // Create recon ISSUER LINK AJA & MERCHANT TYPE MCD
    if (
      form.issuer?.label === ISSUERS.LINK_AJA &&
      form.merchantType?.label === MERCHANT_TYPES.MCD
    ) {
      try {
        loadingScreen(true);
        const result = await createAsyncReconLinkAja({
          merchantTypeId: form.merchantType.value,
          issuerId: form.issuer.value,
          issuerName: form.issuer.label,
          reconDate: form.date,
          linkAjaMCDReport: linkAjaMCDReportUrl,
          ytiMcdSettlement: ytiMcdSettlementUrl,
          ytiSettlement: ytiSettlementUrl,
        });
        loadingScreen(false);
        if (result) {
          popupMessage({
            show: true,
            type: 'waiting',
            title: 'Data Sedang Diproses',
            message: 'Periksa status datamu di menu “Consolidate Report”',
          });
        }
      } catch (err) {
        loadingScreen(false);
        errorBag(err);
      }
    }

    // Create recon ISSUER LINK AJA & MERCHANT TYPE MSME
    if (
      form.issuer?.label === ISSUERS.LINK_AJA &&
      form.merchantType?.label === MERCHANT_TYPES.MSME
    ) {
      try {
        loadingScreen(true);
        const result = await createAsyncReconLinkAjaMsme({
          merchantTypeId: form.merchantType.value,
          issuerId: form.issuer.value,
          issuerName: form.issuer.label,
          reconDate: form.date,
          balanceReport: youtapBalanceUrl,
          linkAjaMCDReport: linkAjaMCDReportUrl,
          opsSettlement: opsSettlementUrl,
          phpDeliveryReport: phpTransactionUrl,
          ytiSettlement: ytiSettlementUrl,
        });
        loadingScreen(false);
        if (result) {
          popupMessage({
            show: true,
            type: 'waiting',
            title: 'Data Sedang Diproses',
            message: 'Periksa status datamu di menu “Consolidate Report”',
          });
        }
      } catch (err) {
        loadingScreen(false);
        errorBag(err);
      }
    }

    // Create recon ISSUER SHOPPE & MERCHANT TYPE MCD
    if (
      form.issuer?.label === ISSUERS.SHOPEE &&
      form.merchantType?.label === MERCHANT_TYPES.MCD
    ) {
      try {
        loadingScreen(true);
        const result = await createAsyncReconShopeeMcd({
          merchantTypeId: form.merchantType.value,
          issuerId: form.issuer.value,
          issuerName: form.issuer.label,
          reconDate: form.date,
          shopeeSettlement: issuerUrl,
          ytiMcdSettlement: ytiMcdSettlementUrl,
        });
        loadingScreen(false);
        if (result) {
          popupMessage({
            show: true,
            type: 'waiting',
            title: 'Data Sedang Diproses',
            message: 'Periksa status datamu di menu “Consolidate Report”',
          });
        }
      } catch (err) {
        loadingScreen(false);
        errorBag(err);
      }
    }

    // Create recon ISSUER KREDIVO & MERCHANT TYPE MCD
    if (
      form.issuer?.label === ISSUERS.KREDIVO &&
      form.merchantType?.label === MERCHANT_TYPES.MCD
    ) {
      try {
        loadingScreen(true);
        const result = await createAsyncReconKredivoMcd({
          merchantTypeId: form.merchantType.value,
          issuerId: form.issuer.value,
          issuerName: form.issuer.label,
          reconDate: form.date,
          kredivoSettlement: issuerUrl,
          ytiMcdSettlement: ytiSettlementUrl,
        });
        loadingScreen(false);
        if (result) {
          popupMessage({
            show: true,
            type: 'waiting',
            title: 'Data Sedang Diproses',
            message: 'Periksa status datamu di menu “Consolidate Report”',
          });
        }
      } catch (err) {
        loadingScreen(false);
        errorBag(err);
      }
    }

    // Create recon ISSUER UANGME & MERCHANT TYPE MCD
    if (
      form.issuer?.label === ISSUERS.UANGME &&
      form.merchantType?.label === MERCHANT_TYPES.MCD
    ) {
      try {
        loadingScreen(true);
        const result = await createAsyncReconUangmeMcd({
          merchantTypeId: form.merchantType.value,
          issuerId: form.issuer.value,
          issuerName: form.issuer.label,
          reconDate: form.date,
          ytiSettlement: ytiSettlementUrl,
          ytiMcdSettlement: ytiMcdSettlementUrl,
        });
        loadingScreen(false);
        if (result) {
          popupMessage({
            show: true,
            type: 'waiting',
            title: 'Data Sedang Diproses',
            message: 'Periksa status datamu di menu “Consolidate Report”',
          });
        }
      } catch (err) {
        loadingScreen(false);
        errorBag(err);
      }
    }

    // Create recon ISSUER LINK AJA & MERCHANT TYPE EXPANSION
    if (
      form.issuer?.label === ISSUERS.LINK_AJA &&
      form.merchantType?.label === MERCHANT_TYPES.EXPANSION
    ) {
      try {
        loadingScreen(true);
        const result = await createAsyncReconLinkAjaExpansion({
          merchantTypeId: form.merchantType.value,
          issuerId: form.issuer.value,
          issuerName: form.issuer.label,
          reconDate: form.date,
          ytiSettlement: ytiSettlementUrl,
          dataExpansion: dataExpansionUrl,
        });
        loadingScreen(false);
        if (result) {
          popupMessage({
            show: true,
            type: 'waiting',
            title: 'Data Sedang Diproses',
            message: 'Periksa status datamu di menu “Consolidate Report”',
          });
        }
      } catch (err) {
        loadingScreen(false);
        errorBag(err);
      }
    }
  }

  useEffect(
    function () {
      if (isLogin && profile) {
        getMerchantTypes({
          page: 0,
          limit: 25,
          order: {
            orderBy: 'NAME',
            sortBy: 'ASC',
          },
        }).catch((err) => errorBag(err));
        getIssuers({
          page: 0,
          limit: 25,
          order: {
            orderBy: 'NAME',
            sortBy: 'ASC',
          },
        }).catch((err) => errorBag(err));
      }
    },
    [isLogin, profile],
  );

  return (
    <>
      <PageInfo title={PERMISSION_LABELS.CONSOLIDATE_DATA} />
      <MainContent title={PERMISSION_LABELS.CONSOLIDATE_DATA}>
        <Form onSubmit={onSubmit}>
          <Row>
            <Col xs={12} sm={12} lg={6}>
              <FormComponent
                form={form}
                error={error}
                onSelectChange={onChangeSelectInput}
                onChange={onChangeFormInput}
              />
            </Col>
          </Row>
          <FormFileComponent
            form={form}
            error={error}
            onChangeFile={onChangeFile}
            isLoading={isLoading}
          />
          <Row className="justify-content-end py-4">
            <Col sm="auto">
              <Button
                variant="info"
                type="submit"
                className="text-light"
                disabled={isLoading}
              >
                Run Data Consolidation
              </Button>
            </Col>
          </Row>
        </Form>
      </MainContent>
      <Toaster />
      {/* {reconResult && <FormResultComponent reconData={reconResult} />} */}
      <Toaster />
    </>
  );
}

export default ConsolidateData;
