import { apoloClient, reconGql } from 'modules';
import { ISSUERS, MERCHANT_TYPES, MESSAGES } from 'utils';
import {
  // CreateReconRequestNew,
  CreateReconRequest,
  CreateReconResponse,
  CreateReconMandiriMutate,
  CreateReconKredivoMutate,
  CreateReconShopeeMutate,
  CreateReconLinkAjaMutate,
  CreateReconLinkAjaMSMEMutate,
  AsyncReconLinkAjaInput,
  AsyncReconLinkAjaMSMEMutate,
  AsyncReconLinkAjaMSMEInput,
  CreateReconLinkAjaMSMEMutateInput,
  AsyncReconLinkAjaMutate,
  AsyncReconKredivoMutate,
  AsyncReconKredivoInput,
  CreateReconLinkAjaMutateInput,
  CreateReconMandiriMutateInput,
  CreateReconKredivoMutateInput,
  CreateReconShopeeMutateInput,
  AsyncReconShopeeMutate,
  AsyncReconShopeeInput,
  AsyncReconMandiriInput,
  AsyncReconMandiriMutate,
  AsyncReconOvoMcdRequest,
  AsyncReconOvoMcdInput,
  AsyncReconOvoMcd,
  AsyncReconOvoMcdMutate,
  AsyncReconMandiriMcdRequest,
  AsyncReconMandiriMcdInput,
  AsyncReconMandiriMcd,
  AsyncReconMandiriMcdMutate,
  AsyncReconLinkAjaMcdRequest,
  AsyncReconLinkAjaMcdInput,
  AsyncReconLinkAjaMcd,
  AsyncReconLinkAjaMcdMutate,
  AsyncReconLinkAjaMsmeRequest,
  AsyncReconLinkAjaMsmeInput,
  AsyncReconLinkAjaMsme,
  AsyncReconLinkAjaMsmeMutate,
  AsyncReconShopeeMcdRequest,
  AsyncReconShopeeMcdInput,
  AsyncReconShopeeMcd,
  AsyncReconShopeeMcdMutate,
  AsyncReconKredivoMcdRequest,
  AsyncReconKredivoMcdInput,
  AsyncReconKredivoMcd,
  AsyncReconKredivoMcdMutate,
  AsyncReconUangmeMcdRequest,
  AsyncReconUangmeMcdInput,
  AsyncReconUangmeMcd,
  AsyncReconUangmeMcdMutate,
  AsyncReconLinkAjaExpansionRequest,
  AsyncReconLinkAjaExpansionInput,
  AsyncReconLinkAjaExpansion,
  AsyncReconLinkAjaExpansionMutate,
} from './interfaces';
import { urlSpaceFormatter } from 'utils';

export async function createReconMandiri(
  input: CreateReconRequest,
): Promise<CreateReconResponse> {
  const response = await apoloClient.mutate<
    CreateReconMandiriMutate,
    CreateReconMandiriMutateInput
  >({
    mutation: reconGql.CREATE_RECON_MANDIRI,
    variables: {
      mandiriSettlement: input.issuerFile,
      ytiMcdSettlement: input.ytiMcdSettlementFile,
    },
  });

  if (!response.data?.uploadReconFile) {
    throw new Error(MESSAGES.NO_RETURN_DATA);
  }
  const responseConsolidate = await apoloClient.mutate<
    AsyncReconMandiriMutate,
    AsyncReconMandiriInput
  >({
    mutation: reconGql.ASYNC_RECON_MANDIRI,
    variables: {
      asyncReconMandiriInput: {
        merchantTypeId: input.merchantType?.value,
        issuerId: input.issuer?.value,
        issuerName: input.issuer?.label,
        reconDate: input.date,
        mandiriSettlement: urlSpaceFormatter(
          response.data?.uploadReconFile?.mandiriSettlement,
        ),
        ytiMcdSettlement: urlSpaceFormatter(
          response.data?.uploadReconFile?.ytiMcdSettlement,
        ),
      },
    },
  });

  if (!responseConsolidate.data?.asyncReconMandiri) {
    throw new Error(MESSAGES.NO_RETURN_DATA);
  }
  return responseConsolidate.data?.asyncReconMandiri;
}

export async function createReconKredivo(
  input: CreateReconRequest,
): Promise<CreateReconResponse> {
  const response = await apoloClient.mutate<
    CreateReconKredivoMutate,
    CreateReconKredivoMutateInput
  >({
    mutation: reconGql.CREATE_RECON_KREDIVO,
    variables: {
      kredivoSettlement: input.issuerFile,
      ytiMcdSettlement: input.ytiMcdSettlementFile,
    },
  });

  if (!response.data?.uploadReconFile) {
    throw new Error(MESSAGES.NO_RETURN_DATA);
  }
  const responseConsolidate = await apoloClient.mutate<
    AsyncReconKredivoMutate,
    AsyncReconKredivoInput
  >({
    mutation: reconGql.ASYNC_RECON_KREDIVO,
    variables: {
      asyncReconKredivoInput: {
        merchantTypeId: input.merchantType?.value,
        issuerId: input.issuer?.value,
        issuerName: input.issuer?.label,
        reconDate: input.date,
        kredivoSettlement: urlSpaceFormatter(
          response.data?.uploadReconFile?.kredivoSettlement,
        ),
        ytiMcdSettlement: urlSpaceFormatter(
          response.data?.uploadReconFile?.ytiMcdSettlement,
        ),
      },
    },
  });

  if (!responseConsolidate.data?.asyncReconKredivo) {
    throw new Error(MESSAGES.NO_RETURN_DATA);
  }
  return responseConsolidate.data?.asyncReconKredivo;
}

export async function createReconShopee(
  input: CreateReconRequest,
): Promise<CreateReconResponse> {
  const response = await apoloClient.mutate<
    CreateReconShopeeMutate,
    CreateReconShopeeMutateInput
  >({
    mutation: reconGql.CREATE_RECON_SHOPEE,
    variables: {
      shopeeSettlement: input.issuerFile,
      ytiMcdSettlement: input.ytiMcdSettlementFile,
    },
  });

  if (!response.data?.uploadReconFile) {
    throw new Error(MESSAGES.NO_RETURN_DATA);
  }
  const responseConsolidate = await apoloClient.mutate<
    AsyncReconShopeeMutate,
    AsyncReconShopeeInput
  >({
    mutation: reconGql.ASYNC_RECON_SHOPEE,
    variables: {
      asyncReconShopeeInput: {
        merchantTypeId: input.merchantType?.value,
        issuerId: input.issuer?.value,
        issuerName: input.issuer?.label,
        reconDate: input.date,
        shopeeSettlement: urlSpaceFormatter(
          response.data?.uploadReconFile?.shopeeSettlement,
        ),

        ytiMcdSettlement: urlSpaceFormatter(
          response.data?.uploadReconFile?.ytiMcdSettlement,
        ),
      },
    },
  });

  if (!responseConsolidate.data?.asyncReconShopee) {
    throw new Error(MESSAGES.NO_RETURN_DATA);
  }
  return responseConsolidate.data?.asyncReconShopee;
}

export async function createReconLinkAja(
  input: CreateReconRequest,
): Promise<CreateReconResponse> {
  const response = await apoloClient.mutate<
    CreateReconLinkAjaMutate,
    CreateReconLinkAjaMutateInput
  >({
    mutation: reconGql.CREATE_RECON_LINKAJA,
    variables: {
      ytiSettlement: input.ytiSettlementFile,
      linkAjaMCDReport: input.linkAjaMCDReportFile,
      ytiMcdSettlement: input.ytiMcdSettlementFile,
    },
  });

  if (!response.data?.uploadReconFile) {
    throw new Error(MESSAGES.NO_RETURN_DATA);
  }

  const responseConsolidate = await apoloClient.mutate<
    AsyncReconLinkAjaMutate,
    AsyncReconLinkAjaInput
  >({
    mutation: reconGql.ASYNC_RECON_LINKAJA,
    variables: {
      asyncReconLinkAjaInput: {
        merchantTypeId: input.merchantType?.value,
        issuerId: input.issuer?.value,
        issuerName: input.issuer?.label,
        reconDate: input.date,
        ytiSettlement: urlSpaceFormatter(
          response.data?.uploadReconFile?.ytiSettlement,
        ),
        linkAjaMCDReport: urlSpaceFormatter(
          response.data?.uploadReconFile?.linkAjaMCDReport,
        ),
        ytiMcdSettlement: urlSpaceFormatter(
          response.data?.uploadReconFile?.ytiMcdSettlement,
        ),
      },
    },
  });

  if (!responseConsolidate.data?.asyncReconLinkAja) {
    throw new Error(MESSAGES.NO_RETURN_DATA);
  }
  return responseConsolidate.data?.asyncReconLinkAja;
}

export async function createReconLinkAjaMSME(
  input: CreateReconRequest,
): Promise<CreateReconResponse> {
  const response = await apoloClient.mutate<
    CreateReconLinkAjaMSMEMutate,
    CreateReconLinkAjaMSMEMutateInput
  >({
    mutation: reconGql.CREATE_RECON_LINKAJA_MSME,
    variables: {
      ytiSettlement: input.ytiSettlementFile,
      linkAjaMCDReport: input.linkAjaMCDReportFile,
      balanceReport: input.youtapBalanceFile,
      phpDeliveryReport: input.phpTransactionFile,
      opsSettlement: input.opsSettlementFile,
    },
  });

  if (!response.data?.uploadReconFile) {
    throw new Error(MESSAGES.NO_RETURN_DATA);
  }

  const responseConsolidate = await apoloClient.mutate<
    AsyncReconLinkAjaMSMEMutate,
    AsyncReconLinkAjaMSMEInput
  >({
    mutation: reconGql.ASYNC_RECON_LINKAJA_MSME,
    variables: {
      asyncReconLinkAjaMSMEInput: {
        merchantTypeId: input.merchantType?.value,
        issuerId: input.issuer?.value,
        issuerName: input.issuer?.label,
        reconDate: input.date,
        ytiSettlement: urlSpaceFormatter(
          response.data?.uploadReconFile?.ytiSettlement,
        ),
        phpDeliveryReport: urlSpaceFormatter(
          response.data?.uploadReconFile?.phpDeliveryReport,
        ),
        balanceReport: urlSpaceFormatter(
          response.data?.uploadReconFile?.balanceReport,
        ),
        linkAjaMCDReport: urlSpaceFormatter(
          response.data?.uploadReconFile?.linkAjaMCDReport,
        ),
        opsSettlement: response?.data?.uploadReconFile?.opsSettlement,
      },
    },
  });

  if (!responseConsolidate.data?.asyncReconLinkAjaMSME) {
    throw new Error(MESSAGES.NO_RETURN_DATA);
  }
  return responseConsolidate.data?.asyncReconLinkAjaMSME;
}

export async function createRecon(
  input: CreateReconRequest,
): Promise<CreateReconResponse> {
  switch (input.issuer?.label) {
    case ISSUERS.MANDIRI_LIVIN:
      return await createReconMandiri(input);
    case ISSUERS.KREDIVO:
      return await createReconKredivo(input);
    case ISSUERS.SHOPEE:
      return await createReconShopee(input);
    case ISSUERS.LINK_AJA:
      switch (input.merchantType?.label) {
        case MERCHANT_TYPES.MCD:
          return await createReconLinkAja(input);
        case MERCHANT_TYPES.MSME:
          return await createReconLinkAjaMSME(input);
        default:
          throw new Error(MESSAGES.NO_RETURN_DATA);
      }
    default:
      throw new Error(MESSAGES.NO_RETURN_DATA);
  }
}

export async function createAsyncReconOvo(
  input: AsyncReconOvoMcdRequest,
): Promise<AsyncReconOvoMcd> {
  const response = await apoloClient.mutate<
    AsyncReconOvoMcdMutate,
    AsyncReconOvoMcdInput
  >({
    mutation: reconGql.ASYNC_RECON_OVO,
    variables: {
      asyncReconOvoInput: {
        merchantTypeId: input.merchantTypeId,
        issuerId: input.issuerId,
        issuerName: input.issuerName,
        reconDate: input.reconDate,
        ovoSettlement: urlSpaceFormatter(input.ovoSettlement),
        ytiMcdSettlement: urlSpaceFormatter(input.ytiMcdSettlement),
      },
    },
  });

  if (!response.data?.asyncReconOvo) {
    throw new Error(MESSAGES.NO_RETURN_DATA);
  }
  return response.data?.asyncReconOvo;
}

export async function createAsyncReconMandiri(
  input: AsyncReconMandiriMcdRequest,
): Promise<AsyncReconMandiriMcd> {
  const response = await apoloClient.mutate<
    AsyncReconMandiriMcdMutate,
    AsyncReconMandiriMcdInput
  >({
    mutation: reconGql.ASYNC_RECON_MANDIRI,
    variables: {
      asyncReconMandiriInput: {
        merchantTypeId: input.merchantTypeId,
        issuerId: input.issuerId,
        issuerName: input.issuerName,
        reconDate: input.reconDate,
        mandiriSettlement: urlSpaceFormatter(input.mandiriSettlement),
        ytiMcdSettlement: urlSpaceFormatter(input.ytiMcdSettlement),
      },
    },
  });

  if (!response.data?.asyncReconMandiri) {
    throw new Error(MESSAGES.NO_RETURN_DATA);
  }
  return response.data?.asyncReconMandiri;
}

export async function createAsyncReconLinkAja(
  input: AsyncReconLinkAjaMcdRequest,
): Promise<AsyncReconLinkAjaMcd> {
  const response = await apoloClient.mutate<
    AsyncReconLinkAjaMcdMutate,
    AsyncReconLinkAjaMcdInput
  >({
    mutation: reconGql.ASYNC_RECON_LINKAJA,
    variables: {
      asyncReconLinkAjaInput: {
        merchantTypeId: input.merchantTypeId,
        issuerId: input.issuerId,
        issuerName: input.issuerName,
        reconDate: input.reconDate,
        ytiMcdSettlement: urlSpaceFormatter(input.ytiMcdSettlement),
        linkAjaMCDReport: urlSpaceFormatter(input.linkAjaMCDReport),
        ytiSettlement: urlSpaceFormatter(input.ytiSettlement),
      },
    },
  });

  if (!response.data?.asyncReconLinkAja) {
    throw new Error(MESSAGES.NO_RETURN_DATA);
  }
  return response.data?.asyncReconLinkAja;
}

export async function createAsyncReconLinkAjaMsme(
  input: AsyncReconLinkAjaMsmeRequest,
): Promise<AsyncReconLinkAjaMsme> {
  const response = await apoloClient.mutate<
    AsyncReconLinkAjaMsmeMutate,
    AsyncReconLinkAjaMsmeInput
  >({
    mutation: reconGql.ASYNC_RECON_LINKAJA_MSME,
    variables: {
      asyncReconLinkAjaMSMEInput: {
        merchantTypeId: input.merchantTypeId,
        issuerId: input.issuerId,
        issuerName: input.issuerName,
        reconDate: input.reconDate,
        balanceReport: urlSpaceFormatter(input.balanceReport),
        linkAjaMCDReport: urlSpaceFormatter(input.linkAjaMCDReport),
        opsSettlement:
          input.opsSettlement === ''
            ? null
            : urlSpaceFormatter(input.opsSettlement),
        phpDeliveryReport: urlSpaceFormatter(input.phpDeliveryReport),
        ytiSettlement: urlSpaceFormatter(input.ytiSettlement),
      },
    },
  });

  if (!response.data?.asyncReconLinkAjaMSME) {
    throw new Error(MESSAGES.NO_RETURN_DATA);
  }
  return response.data?.asyncReconLinkAjaMSME;
}

export async function createAsyncReconShopeeMcd(
  input: AsyncReconShopeeMcdRequest,
): Promise<AsyncReconShopeeMcd> {
  const response = await apoloClient.mutate<
    AsyncReconShopeeMcdMutate,
    AsyncReconShopeeMcdInput
  >({
    mutation: reconGql.ASYNC_RECON_SHOPEE,
    variables: {
      asyncReconShopeeInput: {
        merchantTypeId: input.merchantTypeId,
        issuerId: input.issuerId,
        issuerName: input.issuerName,
        reconDate: input.reconDate,
        shopeeSettlement: urlSpaceFormatter(input.shopeeSettlement),
        ytiMcdSettlement: urlSpaceFormatter(input.ytiMcdSettlement),
      },
    },
  });

  if (!response.data?.asyncReconShopee) {
    throw new Error(MESSAGES.NO_RETURN_DATA);
  }
  return response.data?.asyncReconShopee;
}

export async function createAsyncReconKredivoMcd(
  input: AsyncReconKredivoMcdRequest,
): Promise<AsyncReconKredivoMcd> {
  const response = await apoloClient.mutate<
    AsyncReconKredivoMcdMutate,
    AsyncReconKredivoMcdInput
  >({
    mutation: reconGql.ASYNC_RECON_KREDIVO,
    variables: {
      asyncReconKredivoInput: {
        merchantTypeId: input.merchantTypeId,
        issuerId: input.issuerId,
        issuerName: input.issuerName,
        reconDate: input.reconDate,
        kredivoSettlement: urlSpaceFormatter(input.kredivoSettlement),
        ytiMcdSettlement: urlSpaceFormatter(input.ytiMcdSettlement),
      },
    },
  });

  if (!response.data?.asyncReconKredivo) {
    throw new Error(MESSAGES.NO_RETURN_DATA);
  }
  return response.data?.asyncReconKredivo;
}

export async function createAsyncReconUangmeMcd(
  input: AsyncReconUangmeMcdRequest,
): Promise<AsyncReconUangmeMcd> {
  const response = await apoloClient.mutate<
    AsyncReconUangmeMcdMutate,
    AsyncReconUangmeMcdInput
  >({
    mutation: reconGql.ASYNC_RECON_UANGME,
    variables: {
      asyncReconUangMeInput: {
        merchantTypeId: input.merchantTypeId,
        issuerId: input.issuerId,
        issuerName: input.issuerName,
        reconDate: input.reconDate,
        ytiSettlement: urlSpaceFormatter(input.ytiSettlement),
        ytiMcdSettlement: urlSpaceFormatter(input.ytiMcdSettlement),
      },
    },
  });

  if (!response.data?.asyncReconUangMe) {
    throw new Error(MESSAGES.NO_RETURN_DATA);
  }
  return response.data?.asyncReconUangMe;
}

export async function createAsyncReconLinkAjaExpansion(
  input: AsyncReconLinkAjaExpansionRequest,
): Promise<AsyncReconLinkAjaExpansion> {
  const response = await apoloClient.mutate<
    AsyncReconLinkAjaExpansionMutate,
    AsyncReconLinkAjaExpansionInput
  >({
    mutation: reconGql.ASYNC_RECON_EXPANSION,
    variables: {
      asyncReconExpansionInput: {
        merchantTypeId: input.merchantTypeId,
        issuerId: input.issuerId,
        issuerName: input.issuerName,
        reconDate: input.reconDate,
        ytiSettlement: urlSpaceFormatter(input.ytiSettlement),
        dataExpansion: urlSpaceFormatter(input.dataExpansion),
      },
    },
  });

  if (!response.data?.asyncReconExpansion) {
    throw new Error(MESSAGES.NO_RETURN_DATA);
  }
  return response.data?.asyncReconExpansion;
}
